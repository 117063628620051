/************
 * Developed by : Shiva Software Solutions
 * Date : 17-04-2023
 * Descriptions : Route for appointment page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const appointmentConfig = [
  //Redirect to dashboard page
  {
    auth: authRole.premiumRole,
    routes: [
      {
        path: '/appointment',
        component: React.lazy(() => import('./appointmentPage/index')),
      },
    ],
  },
];
