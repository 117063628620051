/************ 
 * Developed by : Shiva Software Solutions
 * Date : 22-06-2021
 * Descriptions : User Mangament api calling 
 ************/
import {
  FETCH_ERROR,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,   
  RESET_USER_PASWD,
} from '../../shared/constants/ActionTypes';  
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError,onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname  = 'Redux_Actions';
var filename = 'userManagement.js';
// Reset User Password
export const onresetPassword = ({
  oldpaswd,
  newpaswd,
  mobileNbr,
  doctorCode 
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {oldpaswd, newpaswd, mobileNbr, doctorCode};
    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/docMgmt/changepaswdjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token
            try {
              if (data.data.hasOwnProperty.call(data.data, 'message')) {  
                dispatch({
                  type: RESET_USER_PASWD,
                  payload: 'incorrect',
                });
                return;
              }else{
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                oldpaswd, newpaswd, mobileNbr, doctorCode,
                jwtToken,
              };
              jwtAxios
                .post('v1/docMgmt/changepaswd', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: RESET_USER_PASWD,
                      payload: 'true',
                    });
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: getuserdata,
                    });
                   
                  } else {
                    dispatch({
                      type: RESET_USER_PASWD,
                      payload: 'incorrect',
                    }); 
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(onWriteCloudWatchLog(streamname,filename,'onresetPassword','0',error));
                });
              }
            } catch (err) {
              dispatch(fetchError(err.message));
              dispatch(onWriteCloudWatchLog(streamname,filename,'onresetPassword','0',err));
            }
          } else {
            dispatch({
              type: RESET_USER_PASWD,
              payload: 'incorrect',
            }); 
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(onWriteCloudWatchLog(streamname,filename,'onresetPassword','0',error));
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(onWriteCloudWatchLog(streamname,filename,'onresetPassword','0',err));
    }
  };
};
 
 