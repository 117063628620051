/************
 * Developed by : Shiva Software Solutions
 * Date : 21-06-2021
 * Descriptions : Login AND Signup Functionality
 * Dependicies : To use JWT Authendication token
 ************/

import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_AUTH_TOKEN,
  WORD_PREVIEW_CLIENT,
  SET_MOBILENO,
  SET_PASWD,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  OTP_SUCCESS,
  OTP_ERROR,
  OTP_SIGNUP_ERROR,
  SIGNIN_ERROR,
  PATIENT_COMPLETED_COUNT,
  PATIENT_COMPLETED_DATA,
  OTP_UPDTE_ERROR,
  OTP_UPDTE_SUCCESS,
  MOBILE_OTP_SUCCESS,
  SETPASS_DOCID,
  SETPASS_MOB,
} from '../../shared/constants/ActionTypes';

import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  onWriteCloudWatchLog,
} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'JWTAuth.js';

export const onJwtUserSignUp = ({email, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};
export const onGetOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getOtpDoctor', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_SUCCESS,
              payload: true,
            });
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
export const GetmobilenoeditOtp = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/GetmobilenoeditOtp', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: MOBILE_OTP_SUCCESS,
              payload: true,
            });
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
export const GetemaileditOtp = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/GetemaileditOtp', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_SUCCESS,
              payload: true,
            });
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetUpdateOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getOtpUpdateDoctor', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            dispatch({
              type: OTP_UPDTE_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_UPDTE_ERROR,
              payload: '',
            });
            dispatch({
              type: OTP_UPDTE_SUCCESS,
              payload: true,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUpdateOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetUpdateOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
//Forgot password OTP function
export const onGetForgotpaswdOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getForgotPasswordOtpDoctor', value)
        .then((res) => {
          let err_text = 'This Mobile No. does not exists in our records';
          if (value && value['isNumber']) {
            err_text = 'This Mobile No. does not exists in our records';
          } else {
            err_text = `This Email ID doesn't exists in our records`;
          }
          if (res.hasOwnProperty.call(res.data, 'body')) {
            if (res.data.body === 'OTP SENT') {
              dispatch({
                type: OTP_ERROR,
                payload: '',
              });
              dispatch({
                type: OTP_SUCCESS,
                payload: true,
              });
            } else {
              dispatch({
                type: OTP_ERROR,
                payload: err_text,
              });
            }
          } else {
            dispatch({
              type: OTP_ERROR,
              payload: err_text,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetForgotpaswdOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetForgotpaswdOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

export const onJwtSignIn = ({mobileNbr, paswd}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {mobileNbr, paswd};
    try {
      await jwtAxios
        .post('v1/docMgmt/loginjwt', body)
        .then((res) => {
          if (res.data.hasOwnProperty.call(res.data, 'message')) {
            if (res.data.message.includes('Error')) {
              let message = res.data.message.split(':');
              console.log(message[1], 'message');
              dispatch({
                type: SIGNIN_ERROR,
                payload: message[1],
              });
            }
          } else {
            localStorage.setItem('token', res.data.body.token);
            dispatch(setJWTToken(res.data.body.token));
            dispatch(setMobileNbr(mobileNbr));
            dispatch(setPaswd(paswd));
            var body = {mobileNbr, paswd, jwtToken: res.data.body.token};
            dispatch(loadJWTUser(body));
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message.split(':')[1].trim(),
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJwtSignIn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJwtSignIn', '0', err),
      );
    }
  };
};

export const loadJWTUser = (body) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      await jwtAxios
        .post('v1/docMgmt/login', body)
        .then((res) => {
          if (res.status === 200) {
            var response = res.data.body[0];
            var clientList = response?.generateWordClient;
            let locArr = [];
            if (clientList?.length > 0) {
              clientList.map((item) => {
                if (item?.clientID) {
                  let id = Number(item?.clientID);
                  locArr.push(id);
                }
              });
            }
            dispatch({
              type: WORD_PREVIEW_CLIENT,
              payload: locArr,
            });
            localStorage.setItem('roleId', '1');
            let mappedDoctor = response.mappedDoctors
              ? response.mappedDoctors.split(',')
              : [];
            mappedDoctor.push(response.doctorCode.toString());
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                authType: AuthType.JWT_AUTH,
                displayName: response.docname,
                email: response.email,
                mobileNo: response.mobileNo,
                doctorCode: response.doctorCode,
                roleId: response.doctorCategory,
                role:
                  response.doctorCategory == 2 ? 'Doctor' : 'Premium Doctor',
                doctorstatus: response.doctorstatus,
                token: '',
                settings: response.settings,
                mappedDoctor: mappedDoctor,
                doctorcategory: response.doctorCategory,
                logo: response.doctorProfile,
                uniqueDocID: response.uniqueDocID,
                qrCode: response.qrCode,
                transDate: response.transDate,
              },
            });
          } else {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('1')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Mobile No. or password is incorrect',
                });
              }
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'This Mobile No./Email ID is not registered with us',
                });
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: UPDATE_AUTH_USER, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'loadJWTUser',
              '0',
              error,
            ),
          );
          return;
        });
    } catch (err) {
      dispatch(fetchError(err.response.error));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'loadJWTUser', '0', err),
      );
      return;
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const setMobileNbr = (number) => {
  return async (dispatch) => {
    dispatch({
      type: SET_MOBILENO,
      payload: number,
    });
  };
};

export const setPaswd = (paswd) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PASWD,
      payload: paswd,
    });
  };
};

export const onJWTAuthSignout = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      setTimeout(() => {
        dispatch({type: SIGNOUT_AUTH_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: PATIENT_COMPLETED_DATA,
          payload: [],
        });
        dispatch({
          type: PATIENT_COMPLETED_COUNT,
          payload: [],
        });
        localStorage.removeItem('token');
        localStorage.clear();
        window.localStorage.clear();
        window.location.reload();
      }, 500);
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};

export const passwordSetLogout = (value, history, tag) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      if (tag) {
        dispatch({type: SIGNOUT_AUTH_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: PATIENT_COMPLETED_DATA,
          payload: [],
        });
        dispatch({
          type: PATIENT_COMPLETED_COUNT,
          payload: [],
        });
        localStorage.removeItem('token');
        localStorage.clear();
        window.localStorage.clear();
      }
      let body = {
        value: value,
      };
      jwtAxios.post('v1/doctorDtls/checkPassAuth', body).then((data) => {
        if (data.data.status === 200) {
          if (data.data.message.includes('Error')) {
            history.push('/expiredLink');
          } else {
            let response = data.data.body;
            dispatch({
              type: SETPASS_DOCID,
              payload: response?.doctorId,
            });
            sessionStorage.setItem('setpassMobileNo', response?.mobileNo);
            dispatch({
              type: SETPASS_MOB,
              payload: response?.mobileNo,
            });
            history.push('/set-password');
          }
        } else {
          history.push('/expiredLink');
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};
